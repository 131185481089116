/*global SCADAFARM_API_URL, SECONDS*/
/*jshint -W020 */
/*jscs:disable disallowDanglingUnderscores */
// ## SCADAfarm API client
//
// If the SCADAFARM_USERTOKEN constant is available, or if there is a `<meta>`
// tag somewhere in the HTML above the `<script>` tag that requires this file
// looking like this:
//
//     <meta name="token" content="a38db375bf7364...">
//
// then a client is automatically instantiated onto the global variable `sf`.
//
// All methods that hit the network are async and return a `Promise`, no
// exceptions. Use `.done()` and `.fail()` to handle results. The `Promise`s
// returned are *not* `jqXHR`s.
//
// Regarding the difference between `.all()` and `.list()`, the convention is
// that `.all()` returns whatever the API gives us, i.e. an object, while
// `.list()` does some kind of processing on the data, e.g. return only a part
// of it or the results wrapped as `Tag`s, etc.
//
// This library has been designed to avoid __boolean traps__: non-obvious bool
// parameters are not allowed, instead using the literate options pattern, e.g.
//
//     sf.pivot.image_url(code, { vri: true, ... })
//
// -----------------------------------------------------
// Copyright 2015 © McKay Software — All Rights Reserved
(function() {
    var Ajax = {
        fetch: function(url, opts) {
            opts = opts || {};
            opts.url = url;
            opts.xhrFields = { withCredentials: true };
            opts.dataType = opts.dataType || 'json';
            var defer = new $.Deferred();
            opts.timeout = 30 * 1000;
            $.ajax(opts).done(function(data, status, xhr) {
                data.__status = status;
                data.__xhr = xhr;
                if (data.Error || data.error) {
                    defer.reject(data);
                } else {
                    defer.resolve(data);
                }
            }).fail(function(xhr, status, error) {
                defer.reject({
                    error: error,
                    Error: error,
                    __status: status,
                    __xhr: xhr,
                });
            });
            return defer.promise();
        },
        get: function(url, opts) {
            opts = opts || {};
            opts.type = opts.type || 'GET';
            return Ajax.fetch(url, opts);
        },
        post: function(url, opts) {
            opts = opts || {};
            opts.type = opts.type || 'POST';
            return Ajax.fetch(url, opts);
        },
        fail: function(data) {
            var defer = new $.Deferred();
            defer.reject(data);
            return defer.promise();
        },
    };

    var Client = function(token) {
        var client = this;

        this.fetch = function(url, opts) {
            return Ajax.fetch(SCADAFARM_API_URL + url, opts);
        };

        var get = this.get = function(url, opts) {
            return Ajax.get(SCADAFARM_API_URL + url, opts);
        };

        var post = this.post = function(url, opts) {
            return Ajax.post(SCADAFARM_API_URL + url, opts);
        };

        this.tag = {};
        this.tag.get = function(name) {
            return get('/tag/' + name).then(function(data) {
                if (data.tag) { data = data.tag; }
                return new Tag(client, data);
            });
        };

        this.tag.set = function(name, value) {
            return get('/tag/' + name + '?value=' + value).then(function(data) {
                return data.tag;
            });
        };

        this.tag.list = function(names) {
            if ($.isArray(names)) {
                names = names.join();
            }

            return get('/tags/' + names).then(function(data) {
                return data.tags.map(function(tag) {
                    return new Tag(client, tag);
                });
            });
        };

        this.alarm = {};
        this.alarm.get = function(code) {
            return get('/alarm/' + code).then(function(data) {
                return data.alarm;
            });
        };

        this.alarm.acknowledge = function(tag, alarm) {
            return post('/alarm/' + tag + '/' + alarm).then(function(data) {
                if (!data.success) {
                    return Ajax.fail(data);
                }
            });
        };

        this.alarm.list = function(args) {
            return get('/alarm', args);
        };

        // This is the exception that proves the rule, and returns a nodeback.
        this.alarm.poll = function(args, cb) {
            var perform = function() {
                return client.alarm.list(args).done(function(data) {
                    cb(null, data);
                }).fail(function(err) {
                    cb(err, null);
                });
            };

            // First run
            perform().done(function() {
                setInterval(perform, 30000);
            });
        };

        this.installation = {};
        this.installation.is_pivot = function(code) {
            return code && (
                code.indexOf('.I01') >= 0 ||
                code.indexOf('.I04') >= 0 ||
                code.indexOf('.I07') >= 0
            );
        };
        this.installation.is_pump_station = function(code) {
            return code && (
                code.indexOf('.I02') >= 0 ||
                code.indexOf('.I08') >= 0 ||
                code.indexOf('.I09') >= 0 ||
                code.indexOf('.I03') >= 0
            );
        };
        this.installation.is_flow_meter = function(code) {
            return code && (
                code.indexOf('.I09') >= 0
            );
        };
        this.installation.is_moisture_meter = function(code) {
            return code && (
                code.indexOf('.I03') >= 0
            );
        };
        this.installation.image_url = function(code, opts) {
            if (client.installation.is_pivot(code)) {
                return client.pivot.image_url(code, opts);
            }

            if (client.installation.is_pump_station(code)) {
                if (client.installation.is_flow_meter(code)) {
                    return client.flow_meter.image_url(code, opts);
                } else if (client.installation.is_moisture_meter(code)) {
                    return client.moisture_meter.image_url(code, opts);
                } 
                return client.pump_station.image_url(code, opts);
            }

            return '';
        };

        this.installation.url = function(code, tab) {
            var t = (tab ? tab : '#main-tab');

            if (code) {
                if (client.installation.is_pivot(code)) {
                    // I01 - Pivot
                    // I04 - Pivot R2
                    // I07 - Portable Pivot R2
                    return '/pivot/' + code + t;
                } else if (client.installation.is_pump_station(code) ||
                    client.installation.is_flow_meter(code) ||
                    client.installation.is_moisture_meter(code)) {
                    // I02 - Pump Station
                    return '/pump-station/' + code + t;
                }
            }

            return '';
        };

        this.installation.get = function(code) {
            return get('/site/' + code).then(function(data) {
                if (!data.success) {
                    return Ajax.fail(data);
                } else {
                    data.code = code;
                    return new Installation(client, data);
                }
            });
        };

        this.installation.all = function() {
            return get('/site');
        };

        this.tilemap = {};
        this.tilemap.save = function(id, log) {
            return post('/tilemap/' + id, {
                data: {
                    actions: JSON.stringify(log),
                }
            });
        };

        this.weather = {};
        this.weather.get = function(code) {
            return get('/weather/' + code).then(function(data) {
                if (data.success === false) {
                    return Ajax.fail(data);
                }

                return data;
            });
        };

        this.weather.all = function() {
            return get('/weather').then(function(data) {
                if (data.success === false) {
                    return Ajax.fail(data);
                }

                return data;
            });
        };

        this.pivot = {};
        this.pivot.get = function(code) {
            return get('/pivot/' + code).then(function(data) {
                return data;
            });
        };

        this.pivot.image_url = function(code, opts) {
            opts = opts || {};
            var url = SCADAFARM_API_URL;
            if (opts.vri) {
                delete opts.vri;
                opts.time = new Date().getTime();
                url += '/pivot-vri-image/?installationCode=' + code + '&';
            } else {
                url += '/pivot-image/' + code + '?';
            }

            var qs = [];
            $.each(opts, function(key, value) {
                qs.push([key, value].join('='));
            });
            url += qs.join('&');
            return url;
        };

        this.pump_station = {};
        this.pump_station.get = function(code) {
            return get('/pump-station/' + code).then(function(data) {
                return data;
            });
        };

        this.pump_station.image_url = function(code, opts) {
            if (opts.size < 100) {
                return '/assets/images/pump-small.png';
            } else {
                return '/assets/images/pump-large.png';
            }
        };

        this.pump_station.update = function(site_code, pump_index, param_name, new_value) {
            return post('/pump-station/' + site_code + '/pump/' + pump_index + '/' + param_name, {
                data: {
                    value: new_value,
                },
            });
        };

        this.flow_meter = {};
        this.flow_meter.image_url = function(code, opts) {
            if (opts.size < 100) {
                return '/assets/images/flow-small.png';
            } else {
                return '/assets/images/flow-large.png';
            }
        };
        this.moisture_meter = {};
        this.moisture_meter.image_url = function(code, opts) {
            if (opts.size < 100) {
                return '/assets/images/moisture-small.png';
            } else {
                return '/assets/images/moisture-large.png';
            }
        };

    };

    var Installation = function(client, data) {
        var installation = this;
        this.client = client;
        this.data = data;

        this.code = data.code;
        this.site = data.site;
        this.type = +this.code.substr(25, 2);
        this.title = data.title;
        this.alarms = [];
        this.tags = {};
        data.tag.forEach(function(tag) {
            installation.tags[tag.Name] = new Tag(client, tag);
        });

        this.alarm = {};
        this.alarm.list = function() {
            return client.alarm.get(installation.code);
        };

        this.alarm.list().done(function(data) {
            installation.alarms = data;
        });

        // if you subscribe to a tag you'll receive a notification
        // when that tag changes value
        this.subscribed_tags = [];
        this.subscribed_tag_delegates = [];
        this.subscribe_to_tag = function(tag_name, callback) {
            var change_was_made = false;
            if (this.subscribed_tags.indexOf(tag_name) === -1) {
                this.subscribed_tags.push(tag_name);
                change_was_made = true;
            }
            if (callback && this.subscribed_tag_delegates.indexOf(callback) === -1) {
                this.subscribed_tag_delegates.push(callback);
                change_was_made = true;
            }
            if (change_was_made) {
                client.get('/tags/' + installation.code + '/' + tag_name).then(function(response) {
                    for (var tagIndex = 0; tagIndex < response.tags.length; tagIndex += 1) {
                        var t = response.tags[tagIndex];
                        installation.tags[t.Name] = t;
                        if (callback) {
                            var tagObject = new Tag(installation.client, t);
                            callback(tagObject);
                        }
                    }
                });
            }
            return change_was_made;
        };

        this.unsubscribe_from_tag = function(tag_name) {
            var index = this.subscribed_tags.indexOf(tag_name);
            if (index !== -1) {
                this.subscribed_tags.splice(index, 1);
                return true;
            }
            return false;
        };

        this.poll_subscribed_tags = function() {
            if (this.subscribed_tags.length > 0) {
                var tag_csv = this.subscribed_tags.join(',');
                client.get('/tags/' + installation.code + '/' + tag_csv).then(function(response) {
                    // update cached tags
                    for (var tagIndex = 0; tagIndex < response.tags.length; tagIndex += 1) {
                        var t = response.tags[tagIndex];
                        installation.tags[t.Name] = t;

                        var tagObject = new Tag(installation.client, t);

                        // notify callbacks
                        for (var cbIndex = 0; cbIndex < installation.subscribed_tag_delegates.length; cbIndex += 1) {
                            var cb = installation.subscribed_tag_delegates[cbIndex];
                            cb(tagObject);
                        }
                    }
                });
            }
        };

        setInterval(this.poll_subscribed_tags.bind(this), 8 * SECONDS);

        this.tag = {};
        this.tag.get = function(name) {
            installation.subscribe_to_tag(name, null);
            return installation.tags[installation.code + '.' + name];
        };

        this.tag.get_remote = function(name) {
            return client.tag.get(installation.code + '.' + name).then(function(data) {
                installation.tags[data.name] = data;
                return data;
            });
        };

        this.tag.get_object = function(name) {
            installation.subscribe_to_tag(name, null);
            var t = installation.tags[installation.code + '.' + name];
            if (t) {
                return new Tag(installation.client, t);
            }
            return null;
        };

        this.tag.cached = function(name) {
            var tag = installation.tags[installation.code + '.' + name];
            if (typeof tag === 'undefined') {
                installation.tag.get(name);
                tag = {
                    data: {
                        value: null,
                        Value: null,
                    },
                    name: null,
                    value: null,
                    Value: null,
                };
            }
            return tag;
        };

        this.tag.set = function(name, value) {
            return client.tag.set(installation.code + '.' + name, value);
        };

        this.tag.list = function(names) {
            return client.tag.list(installation.code + '/' + names.join());
        };
    };

    var Tag = function(client, data) {
        this.client = client;
        this.data = data;

        if (data.Value === 'True') {
            data.Value = true;
        } else if (data.Value === 'False') {
            data.Value = false;
        }

        this.name = this.Name = data.Name;
        this.value = this.Value = data.Value;
    };

    var Auth = {
        token: {
            request: function(email, password, opts) {
                opts = opts || {};
                var stay_signed_in = !!opts.stay_signed_in;
                delete opts.stay_signed_in;
                if (!email && !password) {
                    throw 'Email & password are required.';
                }
                return Ajax.post(SCADAFARM_API_URL + '/account/request-token', {
                    data: {
                        email: email,
                        password: password,
                        stay_signed_in: stay_signed_in,
                    }
                }, opts).then(function(data) {
                    if (!data.success) {
                        return Ajax.fail(data);
                    }
                });
            },
            invalidate: function(token, opts) {
                opts = opts || {};
                if (!token) {
                    throw 'Token is required.';
                }
                return Ajax.post(SCADAFARM_API_URL + '/account/invalidate-token?token=' + token, opts).then(function(data) {
                    if (!data.success) {
                        return Ajax.fail(data);
                    }
                });
            },
        }
    };

    this.SCADAfarm = Client;
    this.SCADAfarm.Ajax = Ajax;
    this.SCADAfarm.Auth = Auth;

    this.sf = new this.SCADAfarm();

    this.GetURLParameter = function(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i += 1) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] === sParam) {
                return sParameterName[1];
            }
        }
    };
}).apply(this);
