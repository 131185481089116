function changeTabOnLoad() {
    var hash = document.location.hash;
    if (hash) {
        $('.nav-tabs a[href=' + hash + ']').tab('show');
    }
}

$(function() {
    changeTabOnLoad();
    $(window).on('hashchange', changeTabOnLoad);

    $('.nav-tabs a').on('shown.bs.tab', function (e) {
        window.location.hash = e.target.hash;
    });
});
